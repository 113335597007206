<template>
  <footer class="relative z-20 pt-20 pb-5 bg-zinc-900">
    <div
      class="flex flex-col-reverse flex-wrap items-center justify-between w-full px-5 mx-auto mb-10 lg:items-start lg:flex-row md:px-10"
    >
      <div class="flex flex-wrap items-start">
        <div class="mr-20">
          <h4 class="mb-5 text-zinc-500">網站導覽</h4>
          <ol>
            <li
              v-for="(item, item_index) in menu_list"
              :key="`site_map_${item_index}`"
            >
              <router-link
                class="block mb-4 text-sm text-white"
                :to="item.link"
                >{{ item.title }}</router-link
              >
            </li>
          </ol>
        </div>

        <div class="mr-20">
          <h4 class="mb-5 text-zinc-500">條款與規範</h4>
          <ol>
            <li
              v-for="(item, item_index) in terms_list"
              :key="`terms_${item_index}`"
            >
              <router-link
                class="block mb-4 text-sm text-white"
                :to="item.link"
                >{{ item.title }}</router-link
              >
            </li>
          </ol>
        </div>

        <div
          v-if="
            $GetColumn('company_phone') != '' ||
            $GetColumn('company_email') != '' ||
            $GetColumn('company_time') != ''
          "
          class="flex flex-wrap items-start w-full mt-10 md:mt-0 md:w-auto"
        >
          <div class="mr-16">
            <h4 class="mb-5 text-zinc-500">聯絡姿訊</h4>
            <ol class="text-zinc-400">
              <li
                v-if="$GetColumn('company_phone') != ''"
                class="flex flex-wrap items-center mb-4 text-sm sm:mb-2 sm:flex-nowrap"
              >
                <p class="flex-shrink-0 w-full mr-3 text-white sm:w-auto">
                  聯絡電話
                </p>
                <p>{{ $GetColumn("company_phone") }}</p>
              </li>
              <li
                v-if="$GetColumn('company_email') != ''"
                class="flex flex-wrap items-center mb-4 text-sm sm:mb-2 sm:flex-nowrap"
              >
                <p class="flex-shrink-0 w-full mr-3 text-white sm:w-auto">
                  電子郵件
                </p>
                <p>{{ $GetColumn("company_email") }}</p>
              </li>

              <li
                v-if="$GetColumn('company_time') != ''"
                class="flex flex-wrap items-center mb-4 text-sm sm:mb-2 sm:flex-nowrap"
              >
                <p class="flex-shrink-0 w-full mr-3 text-white sm:w-auto">
                  營業時間
                </p>
                <p>{{ $GetColumn("company_time") }}</p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class="mb-10 lg:mb-0">
        <router-link to="/" class="block mb-5">
          <img
            width="240"
            height="38.6"
            :alt="$GetColumn('company_name')"
            src="/img/logo.webp"
            class="w-52"
          />
        </router-link>
        <ol class="flex justify-center text-basic_gray">
          <li
            v-if="$GetColumn('company_facebook') != ''"
            class="flex items-center mb-2 mr-2"
          >
            <a
              aria-label="FacebookPage"
              :href="$GetColumn('company_facebook')"
              target="_blank"
              class="flex items-center justify-center w-10 h-10 text-white rounded-full bg-zinc-800"
            >
              <i class="text-xl text-current icon-facebook"></i>
            </a>
          </li>
          <li
            v-if="$GetColumn('company_instagram') != ''"
            class="flex items-center mb-2 mr-2"
          >
            <a
              aria-label="InstagramPage"
              :href="$GetColumn('company_instagram')"
              target="_blank"
              class="flex items-center justify-center w-10 h-10 text-white rounded-full bg-zinc-800"
            >
              <i class="text-xl text-current icon-instagram"></i>
            </a>
          </li>
          <li
            v-if="$GetColumn('company_line') != ''"
            class="flex items-center mb-2"
          >
            <a
              aria-label="InstagramPage"
              :href="$GetColumn('company_line')"
              target="_blank"
              class="p-2 text-white transition-colors duration-200 rounded-md bg-basic_black hover:bg-primary"
            >
              <LineIcon class="w-5" />
            </a>
          </li>
        </ol>
      </div>
    </div>

    <div
      class="flex flex-wrap items-center justify-center w-full px-5 mx-auto text-white md:px-10 md:justify-end md:border-t md:border-zinc-800 md:pt-5"
    >
      <p class="w-full mr-3 text-sm text-center sm:w-auto sm:text-left">
        © 機因改造 anybuy
      </p>
      <p class="text-sm">
        網站設計
        <a
          class="text-primary"
          target="_blank"
          href="https://www.yongxin-design.com"
          >泳欣事業有限公司</a
        >
      </p>
    </div>
  </footer>
</template>

<script>
import LineIcon from "@/components/svg/LineIcon.vue";
export default {
  name: "MainFooter",
  components: {
    LineIcon,
  },
  data() {
    return {
      menu_list: [
        {
          title: "所有商品",
          link: "/collections",
        },
        {
          title: "最新消息",
          link: "/news",
        },
        {
          title: "影音專區",
          link: "/video",
        },
        {
          title: "廠商專區",
          link: "/account/login",
        },
      ],
      terms_list: [
        {
          title: "服務條款",
          link: "/terms/terms_of_customer",
        },
        {
          title: "隱私權政策",
          link: "/terms/site_privacy",
        },
        {
          title: "訂單查詢",
          link: "/order_search/search",
        },
      ],
    };
  },
};
</script>
