<template>
  <section id="ContactFooter" class="w-full py-10 md:py-20">
    <div class="w-full px-5 mx-auto max-w-screen-2xl 2xl:px-0 md:px-10">
      <div class="flex flex-wrap items-stretch justify-between mb-5 -mx-2">
        <div class="w-full px-2 mb-4 lg:w-1/2 lg:mb-0">
          <div class="flex flex-col h-full p-5 bg-zinc-100 rounded-xl">
            <div class="flex-1 mb-5">
              <span
                class="text-3xl font-black uppercase text-zinc-800 font-anybody"
                >Contact</span
              >
              <h3 class="text-lg font-bold text-primary">聯絡我們</h3>
            </div>

            <div>
              <p class="mb-5 text-sm text-zinc-600">
                需要任何幫助嗎？不管是對產品有任何疑問、安裝說明、購買後的售後服務或是合作洽詢，都歡迎與我們聯絡。
              </p>
              <div>
                <a
                  target="_blank"
                  :href="$GetColumn('company_messenger')"
                  class="flex items-center justify-between w-full p-1 pl-5 border rounded-full contact_btn border-zinc-500"
                >
                  <span class="btn_text">取得聯繫</span>
                  <span
                    class="flex items-center justify-center w-12 h-12 text-white bg-black rounded-full icon_box"
                  >
                    <i class="icon-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-2 lg:w-1/2">
          <div class="w-full h-full overflow-hidden rounded-xl aspect-video">
            <img
              :src="$ImageUrl($GetColumn('contact_footer_image'))"
              class="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-wrap items-stretch justify-between -mx-2">
        <div
          v-for="item_index in 4"
          :key="`core_value_${item_index}`"
          class="w-full px-2 mb-4 sm:w-1/2 lg:w-1/4 lg:mb-0"
        >
          <div
            class="flex items-center w-full h-full px-5 py-6 overflow-hidden bg-zinc-100 rounded-xl"
          >
            <div class="mr-5">
              <img
                :src="$ImageUrl($GetColumn(`feature_${item_index}_image`))"
                class="w-10 md:w-16"
              />
            </div>
            <div>
              <div class="flex-1 mb-1">
                <h4 class="text-lg font-bold text-zinc-900">
                  {{ $GetColumn(`feature_${item_index}_title`) }}
                </h4>
              </div>
              <p class="text-sm text-zinc-600">
                {{ $GetColumn(`feature_${item_index}_content`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactFooter",
};
</script>
