<template>
  <div>
    <button
      @click="status = !status"
      class="flex items-center justify-between w-full px-5 py-3 font-medium link_color text-basic_black"
    >
      <p>{{ title }}</p>
      <PlusIcon v-if="!status" class="w-4" />
      <MinusIcon v-else class="w-4" />
    </button>
    <ol
      class="overflow-hidden transition-all duration-200"
      :class="status ? 'max-h-screen' : 'max-h-0'"
    >
      <li v-for="(item, item_index) in list" :key="`list_${item_index}`">
        <router-link
          @click.native="$emit('close-action')"
          :to="item.link"
          class="block px-5 py-2 text-sm link_color text-zinc-500"
        >
          {{ item.title }}
        </router-link>
      </li>
    </ol>
  </div>
</template>

<script>
import PlusIcon from "@/components/svg/PlusIcon.vue";
import MinusIcon from "@/components/svg/MinusIcon.vue";
export default {
  name: "MenuCollapse",
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    list: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      status: false,
    };
  },
};
</script>
