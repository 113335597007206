import { gsap } from "@/gsap/GsapLoader";

export class ShopCartMessage {
  constructor(el) {
    this.el = el;
    this.dialog_box = el.querySelector("[data-dialog-box]");
    this.background = el.querySelector("[data-dialog-background]");

    this.timeline = null;
  }

  reset() {
    gsap.set(this.el, {
      pointerEvents: "none",
    });
    // shop_cart_dialog ? 'md:translate-x-0 translate-y-0 opacity-100'
    gsap.set(this.dialog_box, { opacity: 0 });
    if (window.innerWidth > 768) {
      gsap.set(this.dialog_box, { x: "100%", y: "0%" });
    } else {
      gsap.set(this.dialog_box, { x: "0%", y: "-100%" });
    }
    gsap.set(this.background, { scaleX: 0, transformOrigin: "left" });

    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    this.timeline.set(this.el, { pointerEvents: "auto" }, "before_open");
    this.timeline.set(this.dialog_box, { opacity: 1 }, "before_open");
    if (window.innerWidth > 768) {
      this.timeline.fromTo(
        this.dialog_box,
        {
          x: "100%",
          y: "0%",
        },
        {
          x: "0%",
          y: "0%",
        },
        "open"
      );
    } else {
      this.timeline.fromTo(
        this.dialog_box,
        {
          x: "0%",
          y: "-100%",
        },
        {
          x: "0%",
          y: "0%",
        },
        "open"
      );
    }

    this.timeline.fromTo(
      this.background,
      { scaleX: 0, transformOrigin: "left" },
      {
        scaleX: 1,
        transformOrigin: "left",
        duration: 5,
        onComplete: () => {
          this.close();
        },
      },
      "open"
    );
  }

  close() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    this.timeline.set(this.el, { pointerEvents: "none" }, "before_close");
    if (window.innerWidth > 768) {
      this.timeline.fromTo(
        this.dialog_box,
        {
          x: "0%",
          y: "0%",
        },
        {
          x: "100%",
          y: "0%",
        },
        "close"
      );
    } else {
      this.timeline.fromTo(
        this.dialog_box,
        {
          x: "0%",
          y: "0%",
        },
        {
          x: "0%",
          y: "-100%",
        },
        "close"
      );
    }

    this.timeline.set(this.dialog_box, { opacity: 0 }, "after_close");
  }
}
