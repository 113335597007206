<template>
  <div id="app" class="relative flex flex-col min-h-screen">
    <MainHeader
      ref="MainHeader"
      v-if="data_load_finish"
      :menu_status="menu_status"
      @menu-action="menu_status = !menu_status"
      @close-menu="menu_status = false"
      @open-search="search_dialog_status = true"
    />
    <MainLoading />
    <ShopCartDrawer v-if="data_load_finish" :view_pt="view_pt" />
    <MainDialog />
    <SearchDialog
      :status="search_dialog_status"
      @close-action="search_dialog_status = false"
    />

    <MainMenu
      ref="MainMenu"
      v-if="data_load_finish"
      :menu_status="menu_status"
      :view_pt="view_pt"
      @close-action="menu_status = false"
    />

    <div
      v-if="data_load_finish"
      class="relative z-10 flex-1"
      :style="`padding-top: ${view_pt}px`"
    >
      <button
        title="ScrollToTop"
        @click="ScrollToTop"
        class="fixed z-20 flex items-center justify-center transition-all duration-300 rounded-full bottom-20 sm:bottom-24 right-5 sm:w-14 sm:h-14 w-11 h-11 bg-primary hover:bg-opacity-70"
      >
        <i
          class="block text-2xl text-white transform -rotate-90 icon-chevron-right"
        ></i>
      </button>
      <button
        v-if="data_load_finish"
        title="MessengerLink"
        @click="OpenMessenger"
        @mouseenter="messenger_hvoer = true"
        @mouseleave="messenger_hvoer = false"
        class="fixed z-20 flex items-center justify-center transition-all duration-300 rounded-full bottom-5 right-5 sm:w-14 sm:h-14 w-11 h-11 bg-primary hover:bg-opacity-70"
      >
        <div
          :class="
            messenger_hvoer ? 'opacity-100 -translate-y-1/2' : 'opacity-0'
          "
          class="md:block hidden absolute pointer-events-none transition-all duration-300 top-1/2 shadow-xl -left-2 transform -translate-x-full bg-white rounded-t-full rounded-bl-full w-[180px] py-2 px-4"
        >
          <p class="block text-primary">歡迎私訊小編喔～</p>
        </div>
        <MessageIcon class="w-6 text-white transform md:w-8 -scale-y-100" />
      </button>

      <router-view :view_pt="view_pt" />
    </div>

    <ContactFooter v-if="data_load_finish" />

    <MainFooter v-if="data_load_finish" />
  </div>
</template>

<style src="@/assets/css/app.css"></style>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainLoading from "@/components/MainLoading";
import MainFooter from "@/components/MainFooter.vue";
import ShopCartDrawer from "@/components/ShopCartDrawer.vue";
import MainDialog from "@/components/MainDialog.vue";
import MessageIcon from "@/components/svg/MessageIcon.vue";
import ContactFooter from "@/components/ContactFooter.vue";
import MainMenu from "./components/MainMenu.vue";
import SearchDialog from "@/components/SearchDialog.vue";
import { mapState } from "vuex";
import { setLocalStorage, delLocalStorage } from "@/common/cookie";
import { GTM_CustomEvent } from "@/common/gtm_methods";
import { getLocalStorage } from "@/common/cookie";

export default {
  name: "App",
  components: {
    MainHeader,
    MainFooter,
    MainLoading,
    ShopCartDrawer,
    MainDialog,
    MessageIcon,
    ContactFooter,
    MainMenu,
    SearchDialog,
  },
  data() {
    return {
      messenger_hvoer: false,
      first_time_load: true,
      view_pt: 0,
      menu_status: false,
      search_dialog_status: false,
    };
  },
  methods: {
    CheckAnnouncementHeight() {
      if (document.querySelector("#HeaderAnnouncement")) {
        this.view_pt =
          document.querySelector("#HeaderAnnouncement").clientHeight + 70;
      } else {
        this.view_pt = 70;
      }
    },
    ScrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    CheckPageData() {
      this.data_load_finish ? "" : this.$store.dispatch("getAllData");

      if (this.is_member) {
        this.$store.dispatch("getMemberNewsData");
        this.$store.dispatch("getMemberNewsCategoryData");
      }
    },
    OpenMenu() {
      this.$refs.MainHeader.Open();
    },
    CloseMenu() {
      this.$refs.MainHeader.Open();
    },
    OpenMessenger() {
      GTM_CustomEvent("open_messenger");
      window.open(this.$GetColumn("company_messenger"), "_blank");
    },
    GetOrderMemo() {
      // 取得分潤標籤
      if (this.$route.query.order_memo != undefined) {
        setLocalStorage("order_memo", this.$route.query.order_memo);
      } else {
        delLocalStorage("order_memo");
      }
    },
  },
  computed: {
    ...mapState(["body_lock"]),
    // 是否登入會員
    is_member() {
      return getLocalStorage("account_token");
    },
    data_load_finish() {
      return this.$store.getters.data_load_finish;
    },
  },
  watch: {
    body_lock() {
      if (this.body_lock != 0) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
    },
    data_load_finish() {
      if (this.data_load_finish) {
        this.$store.dispatch("shopcart_module/GetShopCart");
        this.$nextTick(() => {
          this.CheckAnnouncementHeight();
        });
      }
      if (this.data_load_finish && this.first_time_load) {
        this.first_time_load = false;
      }
    },
    $route() {
      this.GetOrderMemo();
    },
  },
  created() {
    this.CheckPageData();
  },
};
</script>
