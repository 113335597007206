<template>
  <div ref="MainContent" class="fixed top-0 bottom-0 z-30 w-full left-full">
    <nav
      data-menu
      class="absolute top-0 z-10 flex flex-col w-full h-screen max-w-full max-h-screen overflow-y-auto bg-white left-full"
      :style="`padding-top:${view_pt}px`"
    >
      <ol class="flex-1 py-3">
        <li class="px-5 py-3">
          <div
            class="relative flex items-stretch justify-between w-full p-1 border rounded-full border-zinc-300"
          >
            <input
              type="text"
              v-model="key_word"
              @keypress.enter="Search"
              class="flex-1 px-5 bg-transparent focus:outline-none"
            />
            <button
              @click="Search"
              class="flex items-center justify-center w-10 h-10 text-white rounded-full bg-zinc-900"
            >
              <i class="block icon-magnifying-glass"></i>
            </button>
          </div>
        </li>
        <template v-for="item in menu_list">
          <li class="" :key="item.title" v-if="item.type == 'link'">
            <router-link
              @click.native="$emit('close-action')"
              class="flex items-center justify-between w-full px-5 py-3 font-medium link_color"
              :to="item.link"
              >{{ item.title }}
              <i class="block transform icon-chevron-left -scale-100"></i
            ></router-link>
          </li>

          <li class="" :key="item.title" v-if="item.type == 'collapse'">
            <MenuCollapse
              :title="item.title"
              :list="item.list"
              @close-action="$emit('close-action')"
            />
          </li>
        </template>

        <li class="">
          <MenuCollapse
            title="廠商專區"
            :list="member_menu_list"
            @close-action="$emit('close-action')"
          />
        </li>
      </ol>
      <ol class="py-3 border-t border-zinc-200">
        <li>
          <a
            target="_blank"
            :href="$GetColumn('company_messenger')"
            class="block px-5 py-3 link_color"
            >聯絡我們</a
          >
        </li>
        <li>
          <router-link
            to="order_search/search"
            @click.native="$emit('close-action')"
            class="block px-5 py-3 link_color"
            >訂單查詢</router-link
          >
        </li>
      </ol>
    </nav>
    <div
      data-menu-bg
      @click="$emit('close-action')"
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-white bg-opacity-50 opacity-0 backdrop-blur-sm"
    ></div>
  </div>
</template>

<script>
import MenuCollapse from "@/components/main_menu/collapse.vue";
import { menu_gsap_animation } from "@/gsap/main_menu";
export default {
  name: "MainMenu",
  components: {
    MenuCollapse,
  },
  props: {
    menu_status: Boolean,
    view_pt: Number,
  },
  data() {
    return {
      menu_list: [
        {
          title: "首頁",
          link: "/",
          type: "link",
        },
        {
          title: "最新消息",
          link: "/news?category=all",
          type: "link",
        },
        {
          title: "影音專區",
          link: "/video?category=all",
          type: "link",
        },
        {
          title: "所有商品",
          type: "collapse",
          list: [],
        },
      ],
      member_not_login: [
        {
          title: "登入",
          link: "/account/login",
        },
      ],
      member_is_login: [
        {
          title: "店家專區",
          link: "/account/account_edit",
        },
      ],
      menu_gsap_animation: null,
      key_word: "",
    };
  },
  methods: {
    Open() {
      this.key_word = "";
      this.menu_gsap_animation.open();
      this.$store.commit("SetBodyLock", 1);
      this.$LoadAllFont();
    },
    Close() {
      this.menu_gsap_animation.close();
      this.$store.commit("SetBodyLock", -1);
      this.key_word = "";
    },
    Search() {
      if (this.key_word != "") {
        this.$router.push(`/search/${this.key_word}`);
        this.$emit("close-action");
        this.key_word = "";
      }
    },
    SetProductCategoryMenu() {
      let tmp_list = [];
      this.category_data.forEach((item) => {
        tmp_list.push({
          title: item.Title,
          link: `/collections?category=${item.MenuID}`,
        });
      });
      this.menu_list.forEach((item, item_index) => {
        if (item.title == "所有商品") {
          this.menu_list[item_index].list = tmp_list;
        }
      });
    },
  },
  computed: {
    member_token() {
      return this.$store.state.member_token;
    },
    member_menu_list() {
      return this.member_token == ""
        ? this.member_not_login
        : this.member_is_login;
    },
    category_data() {
      return this.$store.state.category_data;
    },
  },
  watch: {
    menu_status() {
      this.menu_status ? this.Open() : this.Close();
    },
  },
  mounted() {
    this.SetProductCategoryMenu();
    this.menu_gsap_animation = new menu_gsap_animation(this.$refs.MainContent);
    window.addEventListener("resize", () => {
      this.$emit("close-action");
    });
  },
};
</script>
