<template>
  <div
    ref="MainContent"
    class="fixed top-0 bottom-0 z-40 flex items-center justify-center w-screen left-full"
  >
    <div
      data-dialog-box
      class="w-[500px] max-w-full bg-white relative z-10 p-8 rounded-2xl"
    >
      <div class="mb-5">
        <p class="text-3xl font-black font-anybody">SEARCH PRODUCT</p>
        <p class="mb-5 text-xl font-bold text-primary">搜尋商品</p>
        <input
          v-model="key_word"
          @keypress.enter="Search"
          type="text"
          class="block w-full px-5 py-3 border rounded-full focus:outline-primary border-zinc-200"
        />
      </div>
      <div class="text-center">
        <button
          @click="Search"
          class="px-10 py-2 text-sm font-medium text-white transition-colors duration-200 border rounded-full bg-primary border-primary hover:bg-transparent hover:text-primary"
        >
          搜尋商品
        </button>
      </div>
    </div>
    <div
      data-dialog-bg
      @click="this.Close"
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-basic_white bg-opacity-80"
    ></div>
  </div>
</template>

<script>
import { dialog_animation } from "@/gsap/dialog";
export default {
  name: "SearchDialog",
  props: {
    status: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog_animation: null,
      key_word: "",
    };
  },
  methods: {
    Close() {
      this.$emit("close-action");
    },
    Search() {
      if (this.key_word != "") {
        this.$router.push("/search/" + this.key_word);
        this.Close();
      }
    },
  },
  watch: {
    status() {
      if (this.status) {
        this.key_word = "";
        this.$LoadAllFont();
        this.dialog_animation.open();
      } else {
        this.dialog_animation.close();
      }
    },
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },
};
</script>
