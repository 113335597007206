import Vue from "vue";
import Vuex from "vuex";
import router from "@/router/index";
import { getAllData } from "@/api/page_data.js";

import { getMemberNewsCategoryData, getMemberNews } from "@/api/member.js";

import { shopcart_module } from "./shopcart";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_dialog: {
      status: false,
      content: "",
    },
    member_token: "",
    shopcart_drawer: false,
    loading: 0,
    body_lock: 0,
    common_column_data: null,
    carousel_data: null,
    news_data: null,
    news_category_data: null,
    video_data: null,
    video_category_data: null,
    category_data: null,
    event_data: null,
    product_data: null,
    zipcode_data: null,
    payment_data: null,
    shipway_data: null,
    all_product_data: null,
    body_text: "",
  },
  getters: {
    data_load_finish(state) {
      if (
        state.common_column_data != null &&
        state.carousel_data != null &&
        state.news_data != null &&
        state.news_category_data != null &&
        state.video_data != null &&
        state.video_category_data != null &&
        state.category_data != null &&
        state.product_data != null &&
        state.zipcode_data != null &&
        state.payment_data != null &&
        state.shipway_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    promote_product_data(state, getters) {
      if (state.product_data == null) {
        return null;
      }
      return getters.main_product_data.filter((item) => {
        return (
          item.Menu.filter((menu) => {
            return menu.MenuID == 7;
          }).length > 0 && item.Status == "Y"
        );
      });
    },
    new_product_data(state, getters) {
      if (state.product_data == null) {
        return null;
      }
      const menu_id = state.category_data.filter((item) => item.MenuID == 1)[0]
        .MenuID;
      return getters.main_product_data.filter((item) => {
        return (
          item.Menu.filter((menu) => {
            return menu.MenuID == menu_id;
          }).length > 0 && item.Status == "Y"
        );
      });
    },
    home_ad_data(state, getters) {
      let images = [];
      images.push({
        Image1: getters.getCommonColumn("home_promote_1_image"),
        Link: getters.getCommonColumn("home_promote_1_link"),
      });
      images.push({
        Image1: getters.getCommonColumn("home_promote_2_image"),
        Link: getters.getCommonColumn("home_promote_2_link"),
      });
      images.push({
        Image1: getters.getCommonColumn("home_promote_3_image"),
        Link: getters.getCommonColumn("home_promote_3_link"),
      });
      return images;
    },
    getCommonColumn: (state) => (key) => {
      if (state.common_column_data == null) {
        return null;
      }
      const column_data = state.common_column_data.filter(
        (column) => column.Title == key
      );
      return column_data.length > 0 ? column_data[0].Content : "";
    },
    event_data: (state) => (key) => {
      if (state.event_data == null) {
        return null;
      }
      const event_data = state.event_data.filter((item) => item.MenuID == key);
      if (event_data.length <= 0) {
        return "error";
      }
      return event_data[0];
    },
    main_product_data: (state) => {
      return state.product_data.filter((item) => {
        let normal_menu_count = 0;
        let sale_page_count = 0;
        item.Menu.forEach((menu) => {
          menu.Content5 == "獨立銷售頁"
            ? (sale_page_count += 1)
            : (normal_menu_count += 1);
        });
        return normal_menu_count > 0;
      });
    },
  },
  mutations: {
    SetBodyText(state) {
      let str = document.body.innerText;
      let uniqueChars = "";
      for (let i = 0; i < str.length; i++) {
        if (
          str.charAt(i) !== " " &&
          uniqueChars.indexOf(str.charAt(i)) === -1
        ) {
          uniqueChars += str[i];
        }
      }
      uniqueChars = uniqueChars.replace(/\s/g, "");
      state.body_text = [...new Set(uniqueChars)].join("");
    },
    SetBodyLock(state, action) {
      state.body_lock + action < 0
        ? (state.body_lock = 0)
        : (state.body_lock += action);
    },
    SetShopcartDrawer(state, action) {
      state.shopcart_drawer = action;
    },
    SetLoading(state, action) {
      const next_val = state.loading + action;
      state.loading = next_val < 0 ? 0 : next_val;
    },
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getAllData(state) {
      state.commit("SetLoading", 1);
      getAllData().then((res) => {
        state.commit("SetStateData", {
          key: "common_column_data",
          val: res.data["Column"].data,
        });
        state.commit("SetStateData", {
          key: "zipcode_data",
          val: res.data["Zip"].data,
        });
        state.commit("SetStateData", {
          key: "news_category_data",
          val: res.data["NewsCategory"].data,
        });
        state.commit("SetStateData", {
          key: "video_category_data",
          val: res.data["VideoCategory"].data,
        });
        state.commit("SetStateData", {
          key: "payment_data",
          val: res.data["Payment"].data,
        });
        state.commit("SetStateData", {
          key: "shipway_data",
          val: res.data["Shipping"].data,
        });

        state.dispatch("getCarouselData", res.data["Carousel"].data);
        state.dispatch("resortAndSetData", {
          data: res.data["News"].data,
          key: "news_data",
        });
        state.dispatch("resortAndSetData", {
          data: res.data["Video"].data,
          key: "video_data",
        });
        state.dispatch("getCategoryData", res.data["Menu"].data);
        state.dispatch("getProductData", res.data["Goods"].data);
        state.commit("SetLoading", -1);
      });
    },
    getCarouselData(state, data) {
      //  設定首頁輪播圖preload 增加效能
      if (router.history.current.name == "首頁") {
        let pc_image_link = document.createElement("link");
        pc_image_link.as = "image";
        pc_image_link.rel = "preload";
        pc_image_link.href = process.env.VUE_APP_BASE_API + data[0].Image1;

        let mb_image_link = document.createElement("link");
        mb_image_link.as = "image";
        mb_image_link.rel = "preload";
        mb_image_link.href = process.env.VUE_APP_BASE_API + data[0].Image2;
        document.head.appendChild(pc_image_link);
        document.head.appendChild(mb_image_link);
      }

      state.commit("SetStateData", {
        key: "carousel_data",
        val: data,
      });
    },
    getCategoryData(state, data) {
      let tmp_data = data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      tmp_data = tmp_data.filter((item) => {
        return item.Status != "N";
      });
      let category = tmp_data.filter((item) => item.Content5 != "獨立銷售頁");
      category = category.filter((item) => {
        if (item.MenuTimeEnd != null) {
          return new Date() < new Date(item.MenuTimeEnd);
        } else {
          return true;
        }
      });
      state.commit("SetStateData", {
        key: "category_data",
        val: category,
      });
      const event = tmp_data.filter((item) => item.Content5 == "獨立銷售頁");
      state.commit("SetStateData", {
        key: "event_data",
        val: event,
      });
    },
    getProductData({ commit }, data) {
      // 篩選已刪除商品
      let tmp_data = data.filter((item) => {
        return item.deleted_at == null;
      });
      // 篩選停用的庫存，並判斷若商品沒有圖片則顯示預設圖片
      tmp_data.forEach((item, item_index) => {
        tmp_data[item_index].Stock = item.Stock.filter(
          (stock) => stock.Status == "Y"
        );
        if (item.Image1 == "") {
          tmp_data[item_index].Image1 = "/image/product_default.webp";
        }
      });
      // 篩選沒有庫存選項的商品
      tmp_data = tmp_data.filter((item) => item.Stock.length > 0);
      // 篩選停用的商品
      tmp_data = tmp_data.filter((item) => item.Status == "Y");
      // 排序
      tmp_data = tmp_data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      commit("SetStateData", {
        key: "all_product_data",
        val: data,
      });
      commit("SetStateData", {
        key: "product_data",
        val: tmp_data,
      });
      commit("shopcart_module/SetProductData", tmp_data, {
        root: true,
      });
    },

    getMemberNewsCategoryData(state) {
      getMemberNewsCategoryData().then((res) => {
        state.commit("SetStateData", {
          key: "news_category_data",
          val: res.data,
        });
      });
    },
    getMemberNewsData(state) {
      getMemberNews().then((res) => {
        let tmp_data = res.data.sort((a, b) => {
          return parseInt(a.Seq) - parseInt(b.Seq);
        });
        state.commit("SetStateData", {
          key: "news_data",
          val: tmp_data,
        });
      });
    },

    resortAndSetData(state, { data, key }) {
      let tmp_data = data.sort((a, b) => {
        return parseInt(a.Seq) - parseInt(b.Seq);
      });
      state.commit("SetStateData", {
        key: key,
        val: tmp_data,
      });
    },
  },
  modules: {
    shopcart_module: shopcart_module,
  },
});
