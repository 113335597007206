import store from '@/store';
// window.dataLayer.push

export function ConvertProductData(product_data) {
  let gtm_product = {
    item_id: product_data.GoodsID,
    item_name: product_data.Title,
    item_brand: GetCompanyName(),
    price: parseInt(GetLowPrice(product_data.Stock)),
    item_variant: GetVariant(product_data.Stock),
    item_list_id:
      product_data.Menu.length > 0 ? product_data.Menu[0].MenuID : '',
    index: product_data.Seq,
    currency: 'TWD',
  };

  for (let i = 1; i <= 5; i++) {
    if (product_data.Menu.length >= i) {
      i == 1
        ? (gtm_product.item_category = product_data.Menu[i - 1].Title)
        : (gtm_product[`item_category${i}`] = product_data.Menu[i - 1].Title);
    }
  }
  return gtm_product;
}

export function ConvertAddShopCartData(product_data, active_options, amount) {
  const ColorTitle = product_data.Stock.filter(
    (item) => item.ColorID == active_options[0]
  )[0].ColorTitle;
  const SizeTitle = product_data.Stock.filter(
    (item) => item.SizeID == active_options[1]
  )[0].SizeTitle;
  const Price = product_data.Stock.filter(
    (item) =>
      item.SizeID == active_options[1] && item.ColorID == active_options[0]
  )[0].SellPrice;

  let gtm_product = {
    item_id: product_data.GoodsID,
    item_name: product_data.Title,
    quantity: amount,
    item_brand: GetCompanyName(),
    price: parseInt(Price),
    item_variant: `${ColorTitle},${SizeTitle}`,
    item_list_id:
      product_data.Menu.length > 0 ? product_data.Menu[0].MenuID : '',
    index: product_data.Seq,
    currency: 'TWD',
  };

  for (let i = 1; i <= 5; i++) {
    if (product_data.Menu.length >= i) {
      i == 1
        ? (gtm_product.item_category = product_data.Menu[i - 1].Title)
        : (gtm_product[`item_category${i}`] = product_data.Menu[i - 1].Title);
    }
  }
  return gtm_product;
}

export function GetCompanyName() {
  const column = store.state.common_column_data.filter(
    (column) => column.Title == 'company_name'
  );
  return column.length <= 0 ? '' : column[0].Content;
}

export function GetLowPrice(stocks) {
  let price = 9999;
  stocks.forEach((item) => {
    parseInt(item.SellPrice) < price ? (price = item.SellPrice) : '';
  });
  return price == 9999 ? 0 : price;
}

export function GetVariant(stocks) {
  return stocks.length > 0
    ? `${stocks[0].ColorTitle},${stocks[0].SizeTitle}`
    : '';
}

export function GTM_AddPaymentInfo(shopcart, payment_id) {
  const payment_type = store.state.payment_data.filter(
    (item) => item.PaymentID == payment_id
  )[0];

  let products = [];

  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      item.amount
    );
    products.push(product);
  });

  window.dataLayer.push({
    event: 'addPaymentInfo',
    items: products,
    value: 0,
    currency: 'TWD',
    payment_type: payment_type.Title,
  });
}

export function GTM_AddShipInfo(shopcart, shipping_id, ship_price) {
  const shipway = store.state.shipway_data.filter(
    (item) => item.ShippingID == shipping_id
  )[0];

  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      item.amount
    );
    products.push(product);
  });

  window.dataLayer.push({
    event: 'addShippingInfo',
    items: products,
    value: 0,
    currency: 'TWD',
    shipping: ship_price,
    shipping_tier: shipway.Title,
  });
}

export function GTM_BeginCheckout(shopcart) {
  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      1
    );
    products.push(product);
  });
  window.dataLayer.push({
    event: 'beginCheckout',
    items: products,
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_Purchase(shopcart, trade_data, coupon_info) {
  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      item.amount
    );
    products.push(product);
  });
  window.dataLayer.push({
    event: 'purchase',
    trade_no: trade_data.TradeID,
    items: products,
    value: trade_data.Price,
    currency: 'TWD',
    tax: 0,
    shipping: trade_data.ShippingFee,
    coupon: coupon_info,
  });
}

export function GTM_AddShopCart(shopcart_item, amount) {
  window.dataLayer.push({
    event: 'add_to_cart',
    items: [
      ConvertAddShopCartData(
        shopcart_item.product_data,
        shopcart_item.active_option,
        amount
      ),
    ],
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_RemoveShopCart(shopcart_item, amount) {
  window.dataLayer.push({
    event: 'remove_from_cart',
    items: [
      ConvertAddShopCartData(
        shopcart_item.product_data,
        shopcart_item.active_option,
        amount
      ),
    ],
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_OpenShopCart(shopcart) {
  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      1
    );
    products.push(product);
  });
  window.dataLayer.push({
    event: 'view_cart',
    items: products,
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_ViewProduct(product_data) {
  window.dataLayer.push({
    event: 'viewProduct',
    items: [ConvertProductData(product_data)],
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_ViewItemList(category_title, category_id) {
  window.dataLayer.push({
    event: 'viewItemList',
    item_list_name: category_title,
    item_list_id: category_id,
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_CustomEvent(event_name) {
  window.dataLayer.push({
    event: event_name,
  });
}
