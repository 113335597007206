<template>
  <div ref="MainContent" class="relative">
    <div
      class="absolute z-0 overflow-hidden text-white transform -translate-x-1/2 translate-y-5 md:translate-y-10 w-72 md:left-auto left-1/2 md:translate-x-0 md:right-10 top-full"
    >
      <div
        data-dialog-box
        class="relative z-10 flex items-center justify-between px-5 py-4 mx-auto overflow-hidden transform bg-white border rounded-sm shadow-md border-zinc-200"
      >
        <div class="relative z-10 w-full text-light-black">
          <div
            class="flex items-center justify-between pb-2 mb-5 border-b border-zinc-200"
          >
            <p class="text-sm font-light text-primary">
              已將商品加入您的購物車
            </p>

            <button @click="CloseShopCartDialog" class="relative z-10 block">
              <i class="text-sm text-light-black icon-close text-primary"></i>
            </button>
          </div>
          <button
            @click="OpenShopCart"
            class="block w-full py-2 text-xs text-center text-white transition-all duration-300 border rounded-full cursor-pointer bg-primary hover:text-primary hover:bg-transparent border-primary"
          >
            查看購物車
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShopCartMessage } from "@/gsap/ShopCartMessage.js";
export default {
  name: "ShopCartMessage",
  data() {
    return {
      dialog_timer: null,
      dialog_gsap: null,
    };
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopcartDrawer", true);
    },
    CloseShopCartDialog() {
      this.$store.commit("shopcart_module/SetAddCartMessage", -99999);
    },
  },
  computed: {
    shop_cart_dialog() {
      return this.$store.state.shopcart_module.add_cart_message;
    },
  },
  watch: {
    shop_cart_dialog() {
      if (this.shop_cart_dialog > 0) {
        this.dialog_gsap.open();
      } else {
        this.dialog_gsap.close();
      }
    },
  },
  mounted() {
    this.dialog_gsap = new ShopCartMessage(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
