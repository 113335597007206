<template>
  <div class="fixed top-0 left-0 z-40 w-full">
    <Announcement ref="Announcement" />
    <header class="relative">
      <div
        class="w-full relative z-10 h-[70px] bg-white text-light-black border-b border-zinc-200"
      >
        <div
          class="relative z-10 flex items-center justify-between w-full h-full pl-5 lg:px-10"
        >
          <router-link
            to="/"
            class="relative z-10 flex items-center h-full transform md:pr-10 md:border-r border-zinc-200"
          >
            <img
              :alt="$GetColumn('company_name')"
              :src="$ImageUrl($GetColumn('logo_image'))"
              class="block w-32"
            />
          </router-link>

          <nav class="h-full">
            <ol class="items-center hidden h-full lg:flex">
              <li
                v-for="(item, item_index) in menu_list"
                :key="`list_item_${item_index}`"
                class="flex items-center h-full px-1"
              >
                <router-link
                  class="flex items-center px-3 py-1 text-sm transition-colors duration-300 bg-transparent rounded-md text-zinc-600 hover:md:text-primary"
                  :to="item.link"
                >
                  {{ item.title }}
                </router-link>
              </li>
              <li class="flex items-center h-full px-1">
                <a
                  target="_blank"
                  :href="$GetColumn('company_messenger')"
                  class="flex items-center px-3 py-1 text-sm transition-colors duration-300 bg-transparent rounded-md text-zinc-600 hover:md:text-primary"
                >
                  聯絡我們
                </a>
              </li>
            </ol>
          </nav>

          <ol class="flex items-center h-full">
            <li class="hidden h-full border-x border-zinc-200 lg:block">
              <button
                @click="$router.push('/account/login')"
                class="flex items-center justify-center h-full transition-colors duration-300 aspect-square text-zinc-600 hover:md:text-primary"
              >
                <i class="text-xl text-current icon-user"></i>
              </button>
            </li>
            <li class="hidden h-full border-r border-zinc-200 lg:block">
              <button
                @click="$emit('open-search')"
                class="flex items-center justify-center h-full transition-colors duration-300 aspect-square text-zinc-600 hover:md:text-primary"
              >
                <i class="text-xl text-current icon-magnifying-glass"></i>
              </button>
            </li>
            <li
              class="block h-full border-l border-r lg:border-l-0 border-zinc-200"
            >
              <button
                v-show="!shop_cart_dialog"
                @click="
                  $store.commit('SetShopcartDrawer', true), $emit('close-menu')
                "
                class="relative flex items-center justify-center h-full transition-colors duration-300 aspect-square text-zinc-600 hover:md:text-primary"
              >
                <span
                  class="absolute -right-0 z-10 px-1 text-xs font-bold h-4 min-w-[16px] block text-white transform -top-0 bg-primary"
                  >{{ shop_cart_count }}</span
                >

                <div class="relative w-6 text-current">
                  <i
                    class="relative z-0 text-xl text-current icon-cart-shopping"
                  ></i>
                </div>
              </button>

              <button
                v-show="shop_cart_dialog"
                @click="$store.commit('SetShopcartDrawer', false)"
                class="block h-full text-white transition-colors duration-300 aspect-square bg-zinc-900"
              >
                <i class="text-xl text-current icon-close"></i>
              </button>
            </li>
            <li class="block h-full lg:hidden">
              <button
                @click="$emit('menu-action')"
                class="block h-full transition-colors duration-300 aspect-square lg:hidden"
                :class="
                  menu_status
                    ? 'bg-zinc-900 text-white'
                    : 'text-zinc-600 hover:md:text-zinc-500'
                "
              >
                <i
                  v-show="!menu_status"
                  class="text-xl text-current icon-menu"
                ></i>
                <i
                  v-show="menu_status"
                  class="text-xl text-current icon-close"
                ></i>
              </button>
            </li>
          </ol>
        </div>
      </div>
      <ShopCartMessage ref="ShopCartMessage" />
    </header>
  </div>
</template>

<script>
import Announcement from "@/components/mainHeader/Announcement.vue";
import ShopCartMessage from "@/components/mainHeader/ShopCartMessage.vue";
export default {
  name: "MainHeader",
  components: {
    Announcement,
    ShopCartMessage,
  },
  data() {
    return {
      menu_list: [
        {
          title: "所有商品",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
      ],
      drop_down: false,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },
  },
  computed: {
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
    shop_cart_dialog() {
      return this.$store.state.shopcart_drawer;
    },
  },
};
</script>
