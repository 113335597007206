<template>
  <nav ref="MainContent" class="fixed top-0 bottom-0 z-30 w-screen left-full">
    <div
      data-menu
      class="absolute top-0 bottom-0 left-full max-w-full sm:w-[532px] w-full bg-white z-10 md:pb-0"
      :style="`padding-top:${view_pt}px`"
    >
      <div class="flex items-center justify-between px-5 pt-5">
        <h4 class="text-xl font-bold sm:text-2xl">
          您的購物車{{
            shopcart.length > 0 ? "( " + shopcart.length + " )" : ""
          }}
        </h4>
      </div>
      <div class="p-5">
        <ol
          class="max-h-[70vh] overflow-y-auto rounded-md border border-zinc-200"
        >
          <li
            class="flex flex-wrap items-start px-5 py-5"
            :class="
              item_index != shopcart.length - 1
                ? 'border-b border-zinc-200'
                : ''
            "
            v-for="(item, item_index) in shopcart"
            :key="`shopcart_${item_index}`"
          >
            <div class="w-1/4 overflow-hidden rounded-lg aspect-square">
              <img
                :src="$ImageUrl(item.product_data.Image1)"
                class="object-cover w-full h-full"
              />
            </div>
            <div
              class="w-3/4 pl-3 mb-8 sm:mb-14"
              v-if="GetActiveOption(item) != 'error'"
            >
              <p class="mb-2 text-sm font-medium xs:text-base">
                {{ item.product_data.Title }}
              </p>
              <p
                v-if="GetActiveOption(item).ColorTitle != '無'"
                class="mb-2 text-xs sm:text-sm text-basic_gray"
              >
                {{ GetActiveOption(item).ColorTitle }}
              </p>
              <p
                v-if="GetActiveOption(item).SizeTitle != '無'"
                class="text-xs sm:text-sm text-basic_gray"
              >
                {{ GetActiveOption(item).SizeTitle }}
              </p>
            </div>
            <div class="flex justify-end w-full sm:mt-3">
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center">
                  <div
                    class="inline-flex items-stretch mr-5 border rounded-md border-zinc-200"
                  >
                    <button
                      title="MinusAmount"
                      @click="Remove(item_index, 1)"
                      class="px-2 py-1"
                    >
                      <i class="text-sm icon-minus text-zinc-400"></i>
                    </button>
                    <input
                      type="text"
                      readonly
                      :value="item.amount"
                      class="w-10 text-center"
                    />
                    <button
                      title="PlusAmount"
                      @click="Add(item_index)"
                      class="px-2 py-1"
                    >
                      <i class="text-sm icon-plus text-zinc-400"></i>
                    </button>
                  </div>

                  <div>
                    <p v-if="!is_member" class="text-sm">
                      NT$
                      {{
                        $MoneyFormat(
                          parseInt(GetActiveOption(item).SellPrice) *
                            item.amount
                        )
                      }}
                    </p>
                    <p v-else class="text-sm">
                      NT$
                      {{
                        $MoneyFormat(
                          parseInt(GetActiveOption(item).MemberSellPrice) *
                            item.amount
                        )
                      }}
                    </p>
                  </div>
                </div>
                <button
                  @click="Remove(item_index, item.amount)"
                  class="text-sm underline text-zinc-600"
                >
                  移除
                </button>
              </div>
            </div>
          </li>
        </ol>
      </div>

      <div class="absolute bottom-0 left-0 right-0 z-10 px-5 py-5">
        <router-link
          to="/shopcart"
          @click.native="$store.commit('SetShopcartDrawer', false)"
          class="block py-4 font-medium text-center text-white transition-colors duration-200 bg-black rounded-full hover:bg-primary"
        >
          前往結帳
        </router-link>
      </div>
    </div>
    <div
      @click="$store.commit('SetShopcartDrawer', false)"
      data-menu-bg
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-zinc-400 bg-opacity-60 backdrop-blur-md"
    ></div>
  </nav>
</template>

<script>
import { menu_gsap_animation } from "@/gsap/main_menu";
import { getLocalStorage } from "@/common/cookie";
import {
  GTM_OpenShopCart,
  GTM_AddShopCart,
  GTM_RemoveShopCart,
} from "@/common/gtm_methods";
export default {
  name: "ShopCartDrawer",
  props: {
    view_pt: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu_gsap_animation: null,
    };
  },
  methods: {
    GetActiveOption(shopcart_item) {
      const stock = shopcart_item.product_data.Stock.filter((item) => {
        return (
          item.ColorID == shopcart_item.active_option[0] &&
          item.SizeID == shopcart_item.active_option[1]
        );
      });
      return stock.length > 0 ? stock[0] : "error";
    },
    Close() {
      // this.$store.commit("SetShopcartDrawer", false);
      this.menu_gsap_animation.close();
      this.$store.commit("SetBodyLock", -1);
    },
    Add(index) {
      GTM_AddShopCart(this.shopcart[index], 1);
      const shop_cart_item = {
        product: this.shopcart[index].product_data,
        options: this.shopcart[index].active_option,
        amount: 1,
      };
      if (getLocalStorage("account_token")) {
        this.$store.dispatch("shopcart_module/AddShopCart", shop_cart_item);
      } else {
        this.$store.commit("shopcart_module/AddShopCart", shop_cart_item);
      }
    },
    Remove(index, count, type = "normal_product") {
      if (type == "normal_product") {
        GTM_RemoveShopCart(this.shopcart[index], count);
      }
      if (getLocalStorage("account_token")) {
        this.$store.dispatch("shopcart_module/RemoveShopCart", {
          index: index,
          amount: count,
        });
      } else {
        this.$store.commit("shopcart_module/RemoveShopCart", {
          index: index,
          amount: count,
        });
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.menu_gsap_animation.open();
        this.$store.commit("SetBodyLock", 1);
        GTM_OpenShopCart(this.shopcart);
      } else {
        this.Close();
      }
    },
  },
  computed: {
    // 是否登入會員
    is_member() {
      return getLocalStorage("account_token");
    },
    shopcart() {
      return this.$store.state.shopcart_module.shopcart;
    },
    dialog() {
      return this.$store.state.shopcart_drawer;
    },
  },
  mounted() {
    this.menu_gsap_animation = new menu_gsap_animation(this.$refs.MainContent);
  },
  created() {
    // 檢查商品選項是否可用 如果不能用就移除
    this.shopcart.forEach((item, item_index) => {
      const stock = this.GetActiveOption(item);
      if (stock == "error") {
        let count = 0;
        while (count < item.amount) {
          this.Remove(item_index, count + 1, "empty_product");
          count += 1;
        }
      }
    });
  },
};
</script>
